import axios from 'axios';

export default class DoctorService {

	getDoctores() {
		//return axios.get('assets/layout/data/doctores.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/doctores`;
		const url = `/api/doctores`;
		return axios.get(url).then(res=>res.data.data);
	}
	// getDoctoresCombo() {
	// 	return axios.get('assets/layout/data/doctorescombo.json').then(res => res.data.data);
	// }
	
	createDoctores(doctores) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/doctores`;
		const url = `/api/doctores`;
		return axios.post(url,doctores).then(res=>res.data.data);
	}
	
	updateDoctores(doctores) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/doctores/${doctores.id_doctor}`;
		const url = `/api/doctores/${doctores.id_doctor}`;
		return axios.put(url,doctores).then(res=>res.data.data);
	}
	
	deleteDoctores(doctores) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/doctores/${doctores.id_doctor}`;
		const url = `/api/doctores/${doctores.id_doctor}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getDoctoresExport() {
		//return axios.get('assets/layout/data/doctores.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/doctoresexport`;
		const url = `/api/doctoresexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}