import axios from 'axios';

export default class CultivoService {

	getCultivos() {
		return axios.get('assets/layout/data/cultivos.json').then(res => res.data.data);
	}
	getCultivosID(id_proceso_cultivo) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/procesocultivos/${id_proceso_cultivo}`;
		const url = `/api/procesocultivos/${id_proceso_cultivo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createCultivos(cultivo) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/procesocultivos`;
		const url = `/api/procesocultivos`;
		return axios.post(url,cultivo).then(res=>res.data.data);
	}
	
	updateCultivos(cultivo) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/procesocultivos/${cultivo.id_proceso_cultivo}`;
		const url = `/api/procesocultivos/${cultivo.id_proceso_cultivo}`;
		return axios.put(url,cultivo).then(res=>res.data.data);
	}
	
	deleteCultivos(cultivo) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/procesocultivos/${cultivo.id_proceso_cultivo}`;
		const url = `/api/procesocultivos/${cultivo.id_proceso_cultivo}`;
		return axios.delete(url).then(res=>res.data.data);
    }
}