import axios from 'axios';

export default class FotosService {

    createFoto(foto){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/fotos`;
		const url = `/api/fotos`;
		return axios.post(url,foto).then(res=>res.data.data);
	}

	getFotoIdMuestra(id_muestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/fotos/${id_muestra}`;
		const url = `/api/fotos/${id_muestra}`;
		return axios.get(url).then(res=>res.data.data);
	}

	deleteFoto(foto){
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/fotos/${foto}`;
		const url = `/api/fotos/${foto}`;
		return axios.delete(url).then(res=>res.data.data);
	}

}