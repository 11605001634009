import axios from 'axios';

export default class ServicioService {

	getServiciosPrecio() {
		//return axios.get('assets/layout/data/servicios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/preciojoin`;
		const url = `/api/preciojoin`;
		return axios.get(url).then(res=>res.data.data);
	}

	getServicios() {
		//return axios.get('assets/layout/data/servicios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/servicios`;
		const url = `/api/servicios`;
		return axios.get(url).then(res=>res.data.data);
	}

	getServiciosPrecios() {
        return axios.get('assets/layout/data/servicioprecio.json').then(res => res.data.data);
	}
	
	createServicios(servicios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/servicios`;
		const url = `/api/servicios`;
		return axios.post(url,servicios).then(res=>res.data.data);
	}
	
	updateServicios(servicios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/servicios/${servicios.id_servicio}`;
		const url = `/api/servicios/${servicios.id_servicio}`;
		return axios.put(url,servicios).then(res=>res.data.data);
	}
	
	deleteServicios(servicios) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/servicios/${servicios.id_servicio}`;
		const url = `/api/servicios/${servicios.id_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getServiciosExport() {
		//return axios.get('assets/layout/data/servicios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/serviciosexport`;
		const url = `/api/serviciosexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}