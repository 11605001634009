<template>
	<div class="p-grid panel-demo dashboard">
        <div class="p-col-12">
            <div class="card">
                 <Toast/>
				<Toolbar>
                    <template v-slot:left>
						<h4>Proceso de Cultivo</h4>
					</template>
					<template v-slot:right>
                    <Button label="Ver Archivos..." icon="pi pi-eye" class="p-button-raised p-mr-2" @click="ClickModalFoto" :disabled="enabledFoto" />
                    <div class="file-input" id="divefile" >
                        <input type="file" id="file" class="file" v-on:change="onFileChange"  accept="image/png, image/jpeg, image/jpg,,.pdf"  >
                        <label class="filename" for="file"  >
                            Subir archivo
                            <p class="file-name"></p>
                        </label>
                    </div>
                        <!-- <input type="file" id="file"  v-on:change="onFileChange" multiple> -->
                        <Button :disabled="id_estatus == 4 || id_estatus == 3" label="Guardar Cambios" @click="SaveProcess" icon="pi pi-check" class="p-button-raised p-mr-2"  />
					</template>
				</Toolbar>
			
                <div class="p-d-flex">
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Folio Muestra</span>
                            <InputNumber  id="desc" v-model="Id_muestra"  />
                            <Button label="Buscar" class="p-button-raised p-mr-2" @click="GetMuestra" />
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Doctor</span>
                            <span class="detail">{{muestra.doctor}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Paciente</span>
                            <span class="detail">{{muestra.paciente}}</span>
                        </div>
                    </div>                
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Servicio</span>
                            <span class="detail">{{nombreservico1}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Estatus</span>
                            <span class="detail">{{muestra.estatu}}</span>
                        </div>
                    </div>
                </div>
            </div>
		</div>
		<div class="p-col-12">
			<div class="card">
                <TabView>
				

                    <TabPanel header="Fechas de aplicación" id="fecha-aplicacion">
						<p>Fechas de aplicación de la terapia celular al paciente</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">1er Fecha estimada inicio</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueInicio1"/>

                                <label for="fechaEnvio">1er Fecha estimada fin</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueFin1"/>

                                <label for="fechaEnvio">1er Fecha *</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarValue1"></Calendar>
                            </div>

                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">2a Fecha estimada inicio</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueInicio2"/>

                                <label for="fechaEnvio">2a Fecha estimada fin</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueFin2"/>
                                
                                <label for="fechaEnvio">2a Fecha</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarValue2"></Calendar>
                            </div>

                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">3a Fecha estimada inicio</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueInicio3"/>

                                <label for="fechaEnvio">3a Fecha estimada fin</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueFin3"/>
                                
                                <label for="fechaEnvio">3a Fecha</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarValue3"></Calendar>
                            </div>
                            
                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">4a Fecha estimada inicio</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueInicio4"/>

                                <label for="fechaEnvio">4a Fecha estimada fin</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" class="calendar-m" v-model="calendarValueFin4"/>
                                
                                <label for="fechaEnvio">4a Fecha</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarValue4"></Calendar>
                            </div>
                        </div>
					</TabPanel>

                    	<TabPanel header="Cultivo bacteriológico">
						<p>Cultivo bacteriológico <strong>antes de empezar</strong> el proceso de cultivo.</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">Fecha *</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="FechaCultivo"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Comentarios generales encontrados</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="comentariocultivo" required="false" rows="2" cols="20" />
                            </div>
                        </div>
                    </TabPanel>

					<TabPanel header="Inicio de cultivo y Recambio de Medios">
						<p>Fecha de inicio de cultivo y recambio de medios</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-3">
                                <label for="fechaEnvio">Inicio de cultivo *</label>
                                <Calendar :disabled="id_estatus == 4 || id_estatus == 3" dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarInicioCultivo"></Calendar>
                                   
                            </div>   
                            <div class="p-field p-col-12 p-md-3">         
                                <label for="fechaEnvio"> </label>                             
                                 <Button :disabled="id_estatus == 4 || id_estatus == 3" label="Agregar Fecha Planeada" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />   
                            </div>       
                                      
                        </div>
                       
                        <DataTable :value="recambios" editMode="row" dataKey="fecha_plan" v-model:editingRows="editingRows"
                            @row-edit-init="onRowEditInit" @row-edit-save="roweditsave" @row-edit-cancel="onRowEditCancel">
                            <!-- <Column field="no" header="No">
                            </Column> -->
                            <Column field="fecha_plan" header="Fecha Planeada">
                                <template #editor="slotProps">
                                    <InputMask :disabled="id_estatus == 4 || id_estatus == 3" id="fecha_plan" mask="9999-99-99" v-model="slotProps.data[slotProps.column.props.field]" placeholder="9999-99-99" slotChar="yyyy-mm-dd" />
                                </template>
                            </Column>
                            <Column field="fecha_real" header="Fecha Real">
                                <template #editor="slotProps">
                                    <InputMask :disabled="id_estatus == 4 || id_estatus == 3" id="fecha_real" mask="9999-99-99" v-model="slotProps.data[slotProps.column.props.field]" placeholder="9999-99-99" slotChar="yyyy-mm-dd"/>
                                </template>
                            </Column>
                            <Column field="responsable" header="Responsable">
                                <template #editor="slotProps">
                                    <InputText :disabled="id_estatus == 4 || id_estatus == 3" v-model="slotProps.data[slotProps.column.props.field]" autofocus />
                                </template>
                            </Column>
                            
                            <!-- <Column field="foto" header="Foto">                                
                                <template #body="slotProps">
                                    <span :class="'customer-badge status-' + slotProps.data.foto">{{slotProps.data.foto}}</span>
                                </template>
                            </Column> -->
                            <!--
                            <Column field="fechaReal" header="Fecha Real">
                                <template #editor="slotProps">
                                    <Dropdown v-model="slotProps.data['inventoryStatus']" :options="statuses" optionLabel="label" optionValue="value" laceholder="Select a Status">
                                        <template #option="slotProps">
                                            <span :class="'product-badge status-' + slotProps.option.value.toLowerCase()">{{slotProps.option.label}}</span>
                                        </template>
                                    </Dropdown>
                                </template>
                                <template #body="slotProps">
                                    {{getStatusLabel(slotProps.data.inventoryStatus)}}
                                </template>
                            </Column>
                            <Column field="price" header="Price">
                                <template #editor="slotProps">
                                    <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                                </template>
                            </Column>
                            -->
                            <Column :rowEditor="true" headerStyle="width:7rem" bodyStyle="text-align:center"></Column>
                            <Column>
						<template #body="slotProps">
							<Button icon="pi pi-trash" :disabled="id_estatus == 4 || id_estatus == 3" class="p-button-rounded p-button-danger" @click="confirmDeleteEstado(slotProps.data)" />
						</template>
					</Column>
                        </DataTable>
					</TabPanel>
				</TabView>

                     <Dialog v-model:visible="estadoDialog" :style="{width: '450px'}" header="" :modal="true" class="p-fluid">
                        <!-- <div class="p-field">
                            <label for="doctor">Responsable</label>
                             <InputText v-model="responsable"   required="true" autofocus />
                             <Dropdown id="servicio" name="servicio" v-model="responsable" :options="usuarios" optionLabel="nombre" placeholder="Selecionar responsable ..." />
					    </div> -->
                        <div class="p-field">
                            <label for="fechaEfectiva">Fecha Planeada *</label>
                            <InputMask :disabled="id_estatus == 4 || id_estatus == 3" mask="9999-99-99" v-model="FechaPlaneada"  placeholder="9999-99-99" slotChar="yyyy-mm-dd" />
					    </div>
                        <!-- <div class="p-field">
                            <label for="fechaEfectiva">Fecha Real</label>
                            <InputMask mask="9999-99-99" v-model="FechaReal"  placeholder="9999-99-99" slotChar="yyyy-mm-dd" />
					    </div> -->
                        
                                  

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveRecambios" />
					</template>
				</Dialog>

                <Dialog v-model:visible="deleteEstadoDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<!-- <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="estado">¿Desea eliminar el estado <b>{{recambios.responsable}}</b>?</span>
					</div> -->
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEstadoDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleterecambios" />
					</template>
				</Dialog>
                 <Dialog v-model:visible="modalfotosdialog" :modal="true" :closable="false">
                    <template #header style="height:0;padding:0;">
                        <span style="font-weight:600;font-size:1.25rem;">{{fileS?fileS.nombre:'Archivos'}}</span>
                        <span style="flex:1;"></span>
                        <Button icon="pi pi-times" class="my-button" @click="closeFile"/>
                    </template>
                    <div>	
                         <ul v-if="filesLoaded && !fileS" class="file-list">
                            <li v-for="value in Lisimg" :key="value.id_foto">
                                <div class="file-link"  @click="fileS = {file:value.img, type: value.tipo, nombre:value.nombre}"><i :class="fileIcon(value.tipo)"></i> {{value.nombre}}</div> 
                                <i class="pi pi-trash del-button"  @click="EliminarFoto(value)"></i>
                            </li>
                         </ul>
                         <div v-if="!filesLoaded"> Cargando... </div>
                         <div v-if="fileS">
                            <!--<div @click="fileS = null">×</div>-->
                            <div v-if="fileS.type === 'application/pdf'">
                               <embed :src="fileS.file" type="application/pdf" width="480" height="540">
                            </div>
                            <div v-else>
                                <img style="max-height: 540px" :src="fileS.file">
                            </div>
                         </div>
                    </div>
				</Dialog>
			</div>
		</div>

   
	</div>
</template>
<script>
import DoctorService from '../service/DoctorService';
import PadecimientoService from '../service/PadecimientoService';
import ServicioService from '../service/ServicioService';
import CultivoService from '../service/CultivoService';
import RecambioService from '../service/RecambioService';
import MuestraService from '../service/MuestraService';
import UsuarioService from '../service/UsuarioService'; 
import FotosService from '../service/FotosService';
import EnvioMuestraService from '../service/EnvioMuestraService';
import moment from 'moment';
import Cookies from "js-cookie"
window.Cookies = Cookies
	export default {
        props: ['select' ],
		data() {
			return {
                Lisimg:[],
                modalfotosdialog: false,
                porcdescuento: 0,
                dataimg:null,
                enabledFoto:true,
                motivodescuento: null,
                descuentoDialog: false,
                servicios: null,
                cultivos: null,
                recambios: null,
                selectedCultivos: null,
                selectedServicios: null,
                selectedAutoValueDoctor: null,
                autoFilteredValueDoctor: [],
                selectedAutoValuePadecimiento: null,
                autoFilteredValuePadecimiento: [],
                calendarValue: null,
                submitted: false,
                condicionesMuestra: null,
                originalRows: null,
                FechaCultivo:null,
                comentariocultivo:null,
                calendarValue1:null,
                calendarValueInicio1:null,
                calendarValueFin1:null,
                calendarValue2:null,
                calendarValueInicio2:null,
                calendarValueFin2:null,
                calendarValue3:null,
                calendarValueInicio3:null,
                calendarValueFin3:null,
                calendarValue4:null,
                calendarValueInicio4:null,
                calendarValueFin4:null,
                calendarInicioCultivo:null,
                fileS:null,
                filesLoaded:false,
                FotosSub:[],

                responsable:"",
                FechaPlaneada:null,
                FechaReal:null,
                FechaRealPost:null,

                estadoDialog: false,
			    deleteEstadoDialog: false,
			    deleteEstadosDialog: false,

                editingCellRows: {},
                editingRows: [],
                dropdownTransportes: [
					{nombre: 'Transporte propio SG Cells'},
					{nombre: 'Transportación terrestre'},
                    {nombre: 'Transportación aérea'},
                    {nombre: 'Otro medio de transporte'},
				],
				dropdownTransporte: null,
				dropdownServicios: [
					{nombre: 'Cultivo de Grasa'},
					{nombre: 'Cultimo de Médulo Ósea'},
                    {nombre: 'Cultivo de Sangre Periférica'},
                    {nombre: 'Cultivo de Piel'}
				],
                dropdownServicio: null,
                Id_muestra:0,
                muestra:{},
                procesoPost1:[],
                fecha_aplicacion_2:null,
                fecha_aplicacion_3:null,
                fecha_aplicacion_4:null,
                usuarios: null,
                recambiomediosPost:[],
                fecha_post:null,
                recambio: {},
                id_estatus:null,
                serviciosN:null,
                nombreservico:null,
                nombreservico1:null,
                recep:null,
                fecha_cultivo_bac:null,
                fecha_inicio_cultivo:null,
                ResponseEnvio : null,
                useconver:null,
			    userdata:null,
			}
        },
        doctorService: null,
        padecimientoService: null,
        servicioService: null,
        cultivoService: null,
        recambioService: null,
        muestraservice:null,
        ResponseCultivo:null,
        usuarioService: null, 
        enviomuestraservice:null,
        fotosService:null,
		created() {
            this.doctorService = new DoctorService();
            this.padecimientoService = new PadecimientoService();
            this.servicioService = new ServicioService();
            this.cultivoService = new CultivoService();
            this.recambioService = new RecambioService();
            this.muestraservice = new MuestraService();
            this.usuarioService = new UsuarioService(); 
            this.enviomuestraservice = new EnvioMuestraService();
            this.fotosService = new FotosService();
            
		},
		mounted() {
            // this.doctorService.getDoctoresCombo().then(data => this.autoValue = data);
            // this.padecimientoService.getPadecimientos().then(data => this.autoValue = data);
            // this.servicioService.getServiciosPrecios().then(data => this.servicios = data);
            // this.cultivoService.getCultivos().then(data => this.cultivos = data);
            // this.recambioService.getRecambios().then(data => this.recambios = data);
            this.usuarioService.getUsuarios().then(data => this.usuarios = data );
            this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
            document.getElementById('file').disabled = true;  
            document.getElementById('divefile').style.opacity=0.6; 
		},
        methods: {
            saveRecambios(){

                console.log(this.FechaReal);

                // if(this.FechaReal !=null){
                //     this.FechaRealPost=this.FechaReal.toISOString().slice(0,10)
                // }

                if(this.Id_muestra !=0){
                    this.recambiomediosPost.push({
                    "id_muestra":this.Id_muestra,                     
                    "fecha_plan":this.FechaPlaneada,
                    "fecha_real":this.FechaRealPost,
                    "responsable":this.responsable, 
                });
                console.log(this.recambiomediosPost);

                this.recambioService.createrecambioservice(this.recambiomediosPost[0]).then( );
                this.recambiomediosPost=[];
                this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
                this.FechaPlaneada=null;
                this.GetMuestra();
                this.hideDialog();

                }


                

            }, 
			 GetMuestra(){
                 if(this.Id_muestra !=0){
                    this.filesLoaded = false;
                    this.muestraservice.getMuestrasID(this.Id_muestra).then(data => 
                    {
                        if(typeof(data.data1[0]) !='undefined'){
                            document.getElementById('file').disabled = false;   
                            document.getElementById('divefile').style.opacity=1;     
                         
                        this.enabledFoto=false;        
                        this.muestra = data.data1[0];
                        this.recep=data.data4;
                        this.muestra.paciente = data.data1[0].paciente+" "+data.data1[0].pacienteApP+" "+data.data1[0].pacienteApM;
                        this.clave_estado=data.data1[0].clave_estado;
                        this.id_estatus=data.data1[0].id_estatus;
                        this.serviciosN=data.data2;
                        this.nombreservico=null;

                        if(this.serviciosN != null){
                            for(var id1 in this.serviciosN){                           
                                this.nombreservico=this.nombreservico+' '+this.serviciosN[id1].nombre;
                                this.nombreservico1=this.nombreservico.replace("null", "");
                            }
                        }
                         

                        this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);

                    
                        var dias = 1;
                        this.cultivoService.getCultivosID(this.Id_muestra).then(data => {
                        this.ResponseCultivo = data[0]
                        if(this.ResponseCultivo != null)
                        {                            
                            if(this.ResponseCultivo.fecha_cultivo_bac != null)
                            {
                                this.FechaCultivo=new Date(this.ResponseCultivo.fecha_cultivo_bac);
                                this.FechaCultivo.setDate(this.FechaCultivo.getDate() + dias);
                            }                      

                            this.comentariocultivo=this.ResponseCultivo.comentarios;
                            
                            if(this.ResponseCultivo.fecha_aplicacion_1 !=null)
                            {
                                this.calendarValue1=new Date(this.ResponseCultivo.fecha_aplicacion_1);
                                this.calendarValue1.setDate(this.calendarValue1.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValue1=this.ResponseCultivo.fecha_aplicacion_1;
                            }

                            if(this.ResponseCultivo.fecha_est_inicio_1 !=null)
                            {
                                this.calendarValueInicio1=new Date(this.ResponseCultivo.fecha_est_inicio_1);
                                this.calendarValueInicio1.setDate(this.calendarValueInicio1.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueInicio1=this.ResponseCultivo.fecha_est_inicio_1;
                            }

                            if(this.ResponseCultivo.fecha_est_fin_1 !=null)
                            {
                                this.calendarValueFin1=new Date(this.ResponseCultivo.fecha_est_fin_1);
                                this.calendarValueFin1.setDate(this.calendarValueFin1.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueFin1=this.ResponseCultivo.fecha_est_fin_1;
                            }
 
                            if(this.ResponseCultivo.fecha_aplicacion_2 !=null)
                            {
                                this.calendarValue2=new Date(this.ResponseCultivo.fecha_aplicacion_2);
                                this.calendarValue2.setDate(this.calendarValue2.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValue2=this.ResponseCultivo.fecha_aplicacion_2;
                            }

                            if(this.ResponseCultivo.fecha_est_inicio_2 !=null)
                            {
                                this.calendarValueInicio2=new Date(this.ResponseCultivo.fecha_est_inicio_2);
                                this.calendarValueInicio2.setDate(this.calendarValueInicio2.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueInicio2=this.ResponseCultivo.fecha_est_inicio_2;
                            }

                            if(this.ResponseCultivo.fecha_est_fin_2 !=null)
                            {
                                this.calendarValueFin2=new Date(this.ResponseCultivo.fecha_est_fin_2);
                                this.calendarValueFin2.setDate(this.calendarValueFin2.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueFin2=this.ResponseCultivo.fecha_est_fin_2;
                            }

                            // this.calendarValue3=this.ResponseCultivo.fecha_aplicacion_3;
                            if(this.ResponseCultivo.fecha_aplicacion_3 !=null)
                            {
                                this.calendarValue3=new Date(this.ResponseCultivo.fecha_aplicacion_3);
                                this.calendarValue3.setDate(this.calendarValue3.getDate() + dias);
                                
                            }
                            else
                            {
                                this.calendarValue3=this.ResponseCultivo.fecha_aplicacion_3;
                            }

                            if(this.ResponseCultivo.fecha_est_inicio_3 !=null)
                            {
                                this.calendarValueInicio3=new Date(this.ResponseCultivo.fecha_est_inicio_3);
                                this.calendarValueInicio3.setDate(this.calendarValueInicio3.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueInicio3=this.ResponseCultivo.fecha_est_inicio_3;
                            }

                            if(this.ResponseCultivo.fecha_est_fin_3 !=null)
                            {
                                this.calendarValueFin3=new Date(this.ResponseCultivo.fecha_est_fin_3);
                                this.calendarValueFin3.setDate(this.calendarValueFin3.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueFin3=this.ResponseCultivo.fecha_est_fin_3;
                            }
                            
                            if(this.ResponseCultivo.fecha_aplicacion_4 !=null)
                            {
                                this.calendarValue4=new Date(this.ResponseCultivo.fecha_aplicacion_4);
                                this.calendarValue4.setDate(this.calendarValue4.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValue4=this.ResponseCultivo.fecha_aplicacion_4;
                            }

                            if(this.ResponseCultivo.fecha_est_inicio_4 !=null)
                            {
                                this.calendarValueInicio4=new Date(this.ResponseCultivo.fecha_est_inicio_4);
                                this.calendarValueInicio4.setDate(this.calendarValueInicio4.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueInicio4=this.ResponseCultivo.fecha_est_inicio_4;
                            }

                            if(this.ResponseCultivo.fecha_est_fin_4 !=null)
                            {
                                this.calendarValueFin4=new Date(this.ResponseCultivo.fecha_est_fin_4);
                                this.calendarValueFin4.setDate(this.calendarValueFin4.getDate() + dias);
                            }
                            else
                            {
                                this.calendarValueFin4=this.ResponseCultivo.fecha_est_fin_4;
                            }


                            // this.calendarInicioCultivo=this.ResponseCultivo.fecha_inicio_cultivo;
                            if(this.ResponseCultivo.fecha_inicio_cultivo !=null)
                            {
                                    // this.calendarInicioCultivo = new Date(this.ResponseCultivo.fecha_inicio_cultivo);
                                this.calendarInicioCultivo=new Date(this.ResponseCultivo.fecha_inicio_cultivo);
                                this.calendarInicioCultivo.setDate(this.calendarInicioCultivo.getDate() + dias);
                            }
                            else
                            {
                                this.calendarInicioCultivo=this.ResponseCultivo.fecha_inicio_cultivo;
                            }
                            console.log(this.ResponseCultivo);
                        }    
                        });
                        
                        }else{
                            this.muestra.doctor = ''; 
                            this.muestra.paciente = ''; 
                            this.nombreservico1 = ''; 
                            this.muestra.estatu = ''; 
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No existe el folio de Muestra ', life: 4000});
                            this.Id_muestra=0;
                            this.calendarValue1=null;
                            this.calendarValue2=null;
                            this.calendarValue3=null;
                            this.calendarValue4=null;
                            
                            this.FechaCultivo=null;
                            this.comentariocultivo=null;

                            this.calendarInicioCultivo=null; 
                            this.enabledFoto=true;   
                            document.getElementById('file').disabled = true; 
                            document.getElementById('divefile').style.opacity=0.6;  
                        }
                    }
                        ); 

                    

                        this.enviomuestraservice.getUEnvioMuestraID(this.Id_muestra).then(data => 
                        {
                            this.ResponseEnvio = data[0]; 
                        });

                     
                   

                    
                    }
             },
             SaveProcess(){
                if(this.muestra != 0){

                    
                    if(this.FechaCultivo !=null){
                        this.fecha_cultivo_bac=this.computedDates(this.FechaCultivo)
                    }
                    if(this.calendarInicioCultivo !=null){
                        this.fecha_inicio_cultivo=this.computedDates(this.calendarInicioCultivo)
                    }
                    if(this.calendarValue2 !=null){
                        this.fecha_aplicacion_2=this.computedDates(this.calendarValue2)
                    }
                    if(this.calendarValueInicio2 !=null){
                        this.fecha_aplicacion_est_inicio_2=this.computedDates(this.calendarValueInicio2)
                    }
                    if(this.calendarValueFin2 !=null){
                        this.fecha_aplicacion_est_fin_2=this.computedDates(this.calendarValueFin2)
                    }
                    if(this.calendarValue3 !=null){
                        this.fecha_aplicacion_3=this.computedDates(this.calendarValue3)
                    }
                    if(this.calendarValueInicio3 !=null){
                        this.fecha_aplicacion_est_inicio_3=this.computedDates(this.calendarValueInicio3)
                    }
                    if(this.calendarValueFin3 !=null){
                        this.fecha_aplicacion_est_fin_3=this.computedDates(this.calendarValueFin3)
                    }
                    if(this.calendarValue4 !=null){
                        this.fecha_aplicacion_4=this.computedDates(this.calendarValue4)
                    }
                    if(this.calendarValueInicio4 !=null){
                        this.fecha_aplicacion_est_inicio_4=this.computedDates(this.calendarValueInicio4)
                    }
                    if(this.calendarValueFin4 !=null){
                        this.fecha_aplicacion_est_fin_4=this.computedDates(this.calendarValueFin4)
                    }

                    if(this.ResponseCultivo ==null){
                        console.log(this.recep);
                            if(this.computedDates(this.calendarValue1)>=this.ResponseEnvio.fecha_llegada)
                            {
                                this.procesoPost1.push({
                                    "id_muestra":this.Id_muestra,
                                    "fecha_cultivo_bac":this.fecha_cultivo_bac,
                                    "comentarios":this.comentariocultivo,
                                    "fecha_aplicacion_1":this.computedDates(this.calendarValue1),
                                    "fecha_est_inicio_1":this.computedDates(this.calendarValue1),
                                    "fecha_est_fin_1":this.computedDates(this.calendarValue1),
                                    "fecha_aplicacion_2":this.fecha_aplicacion_2, 
                                    "fecha_est_inicio_2":this.fecha_aplicacion_est_inicio_2, 
                                    "fecha_est_fin_2":this.fecha_aplicacion_est_fin_2, 
                                    "fecha_aplicacion_3":this.fecha_aplicacion_3,
                                    "fecha_est_inicio_3":this.fecha_aplicacion_est_inicio_3,
                                    "fecha_est_fin_3":this.fecha_aplicacion_est_fin_3,
                                    "fecha_aplicacion_4":this.fecha_aplicacion_4,
                                    "fecha_est_inicio_4":this.fecha_aplicacion_est_inicio_4,
                                    "fecha_est_fin_4":this.fecha_aplicacion_est_fin_4,
                                    "fecha_inicio_cultivo":this.fecha_inicio_cultivo,
                                });
                                  console.log(this.procesoPost1);
                                this.cultivoService.createCultivos(this.procesoPost1[0]).then(data => this.ResponseCultivo = data); 
                                console.log(this.ResponseCultivo);
                                this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                            }else
                            {
                                this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La primera fecha es menor, favor de revisar', life: 3000});
                            }
                                  
                    }else
                    {
                        var validateFecha=true;                       
                        
                        if(this.calendarValueFin1 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.computedDates(this.calendarValueFin1), this.computedDates(this.calendarValueInicio1), 'La primer fecha tentativa final es menor a la inicial, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValue2 !=null){
                            if(validateFecha){ 
                            validateFecha = this.compareFecha(this.fecha_aplicacion_2, this.computedDates(this.calendarValue1), 'La segunda fecha es menor, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValueInicio2 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_inicio_2, this.computedDates(this.calendarValueInicio1), 'La segunda fecha inicial tentativa es menor a la primera, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValueFin2 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_fin_2, this.fecha_aplicacion_est_inicio_2, 'La segunda fecha tentativa final es menor a la inicial, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValue3 !=null){
                            if(validateFecha){ 
                            validateFecha = this.compareFecha(this.fecha_aplicacion_3, this.fecha_aplicacion_2, 'La tercera fecha es menor, favor de revisar' );
                            }
                        }
                        if(this.calendarValueInicio3 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_inicio_3, this.fecha_aplicacion_est_inicio_2, 'La tercera fecha inicial tentativa es menor a la segunda, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValueFin3 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_fin_3, this.fecha_aplicacion_est_inicio_3, 'La tercera fecha tentativa final es menor a la inicial, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValue4 !=null){
                           if(validateFecha){ 
                           validateFecha = this.compareFecha(this.fecha_aplicacion_4, this.fecha_aplicacion_3, 'La cuarta fecha es menor, favor de revisar' );
                           }
                        }
                        if(this.calendarValueInicio4 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_inicio_4, this.fecha_aplicacion_est_inicio_3, 'La tercera fecha inicial tentativa es menor a la segunda, favor de revisar' );
                            }                            
                        }
                        if(this.calendarValueFin4 !=null){
                            if(validateFecha){
                            validateFecha = this.compareFecha(this.fecha_aplicacion_est_fin_4, this.fecha_aplicacion_est_inicio_4, 'La cuarta fecha tentativa final es menor a la inicial, favor de revisar' );
                            }                            
                        }

                         if(this.FechaCultivo !=null)
                        {
                            if(validateFecha){ 
                            validateFecha = this.compareFecha(this.fecha_cultivo_bac, this.ResponseEnvio.fecha_llegada, 'La fecha de cultivo es menor a la fecha de llegada de kit' );
                            }
                        }

                        if(this.calendarInicioCultivo !=null)
                        {
                            if(validateFecha){ 
                            validateFecha = this.compareFecha(this.fecha_inicio_cultivo, this.fecha_cultivo_bac, 'La fecha de inicio cultivo es menor a la fecha de llegada de kit' );
                            }
                        }

                        if(this.computedDates(this.calendarValue1)>=this.ResponseEnvio.fecha_llegada)
                        {    
                            if(validateFecha)
                            {  
                                this.procesoPost1.push({
                                    "id_proceso_cultivo":this.ResponseCultivo.id_proceso_cultivo,
                                    "id_muestra":this.Id_muestra,
                                    "fecha_cultivo_bac":this.fecha_cultivo_bac,
                                    "comentarios":this.comentariocultivo,
                                    "fecha_aplicacion_1":this.computedDates(this.calendarValue1),
                                    "fecha_est_inicio_1":this.computedDates(this.calendarValue1),
                                    "fecha_est_fin_1":this.computedDates(this.calendarValue1),
                                    "fecha_aplicacion_2":this.fecha_aplicacion_2, 
                                    "fecha_est_inicio_2":this.fecha_aplicacion_est_inicio_2, 
                                    "fecha_est_fin_2":this.fecha_aplicacion_est_fin_2, 
                                    "fecha_aplicacion_3":this.fecha_aplicacion_3,
                                    "fecha_est_inicio_3":this.fecha_aplicacion_est_inicio_3,
                                    "fecha_est_fin_3":this.fecha_aplicacion_est_fin_3,
                                    "fecha_aplicacion_4":this.fecha_aplicacion_4,
                                    "fecha_est_inicio_4":this.fecha_aplicacion_est_inicio_4,
                                    "fecha_est_fin_4":this.fecha_aplicacion_est_fin_4,
                                    "fecha_inicio_cultivo":this.fecha_inicio_cultivo,
                                });
                                console.log(this.procesoPost1);
                                this.cultivoService.updateCultivos(this.procesoPost1[0]).then(); 
                                this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha actualizado correctamente', life: 3000});
                            }
                            // else
                            // {
                            //     this.$toast.add({severity:'warn', summary: 'Mensaje', detail:  'Favor de revisar las fechas', life: 3000});
                            // }
                        }
                        else
                        {
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La primera fecha es menor, favor de revisar', life: 3000});
                        }
                                    
                    }
                    this.procesoPost1=[];
                 
                }
                 
            },

            compareFecha(fecha1, fecha2, message){
                if(fecha1 >= fecha2){
                    return true;
                }             
                else{
                    this.$toast.add({severity:'warn', summary: 'Mensaje', detail:  message, life: 3000});
                    return false;
                }
            },



            openNew() {
                this.submitted = false;
                this.descuentoDialog = true; 
			    this.estadoDialog = true;
            },
            hideDialog() {
			    this.descuentoDialog = false;
                this.submitted = false;
                this.estadoDialog = false;
            },
            saveDescuento() {
                this.submitted = true;
                this.descuentoDialog = false;                           
            },
            onCellEdit(newValue, props) {
                console.log(newValue);
                console.log(props);
                console.log(2121)
                // if (!this.editingCellRows[props.index]) {
                //     this.editingCellRows[props.index] = {...props.data};
                // }

                // this.editingCellRows[props.index][props.column.props.field] = newValue;
            },
            isPositiveInteger(val) {
                console.log(21112)
                let str = String(val);
                str = str.trim();
                if (!str) {
                    return false;
                }
                str = str.replace(/^0+/, "") || "0";
                var n = Math.floor(Number(str));
                return n !== Infinity && String(n) === str && n >= 0;
            },
            onRowEditInit(event) { 
                console.log(event);                

                // this.recambioService.updaterecambioservice(this.recambiomediosPost[0]).then( );
                // this.recambiomediosPost=[];
                // this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
                // this.FechaPlaneada=null;
                
                // this.originalRows[event.index] = {...this.recambios[event.index]};
            },
            roweditsave(event){

                 
                // console.log(event);
                // console.log(2211) 
                // if(event.data.fecha_real !=null){
                //     this.fecha_post=event.data.fecha_real.toISOString().slice(0,10);
                // }

                this.recambiomediosPost.push({
                    "id_recambio_medio":event.data.id_recambio_medio, 
                    "id_muestra":event.data.Id_muestra,                     
                    "fecha_plan":event.data.fecha_plan,
                    "fecha_real":event.data.fecha_real,
                    "responsable":event.data.responsable, 
                });
                console.log(this.recambiomediosPost);
                this.recambioService.updaterecambioservice(this.recambiomediosPost[0]).then( );
                this.recambiomediosPost=[];
                this.GetMuestra();
                this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
            },
            onRowEditCancel(event) {
                console.log(event)
                // console.log(52)
                // this.recambios[event.index] = this.originalRows[event.index];
            },
            getStatusLabel(status) {
                switch(status) {
                    case 'INSTOCK':
                        return 'In Stock';

                    case 'LOWSTOCK':
                        return 'Low Stock';

                    case 'OUTOFSTOCK':
                        return 'Out of Stock';

                    default:
                        return 'NA';
                }
            },
            deleterecambios() { 
                // console.log(this.recambio);
                this.recambioService.deleterecambioservice(this.recambio).then();
                this.recambio={};
                this.GetMuestra();
                this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
                 
                this.deleteEstadoDialog = false; 
                					
                // this.$toast.add({severity:'success', summary: 'Successful', detail: 'Estado eliminado', life: 3000});
                // document.location.reload();
            },
            confirmDeleteEstado(recambio) {
                this.recambio = recambio;
                this.deleteEstadoDialog = true;
            },
            computedDates (value) {
            return moment(value).format('YYYY-MM-DD')
          },
          thumbUrl (file) {
            return file.myThumbUrlProperty
            },

        fileIcon(type){
            const images = new Set(['image/jpeg', 'image/png'])
            if(type === 'application/pdf'){
                return 'pi pi-file-pdf'
            }
            else if(images.has(type)){
                return 'pi pi-image'
            }
            else{
                return 'pi pi-file-o';
            }
        },
          onFileChange(event){            
            // const [file] = event.target.files;

            // const { name: fileName } = file;
            // console.log(fileName);
            // document.querySelector('.filename').textContent = fileName;

            const selectedFile = event.target.files[0];
            console.log(selectedFile);
            // console.log( event.target.files[0].name);
            this.CreateBase64(selectedFile);
          },
          CreateBase64(fileObj){
                const reader= new FileReader();
                reader.readAsDataURL(fileObj);
                reader.onload= (e) =>{
                    // console.log(e.target.result);
                    this.useconver = Cookies.get("user");

                    if(this.useconver != null || this.useconver !="null"){
                        this.userdata=JSON.parse(this.useconver)												 
                    }
                     this.FotosSub=[];
                    this.FotosSub.push({					 
						"id_muestra":this.Id_muestra,
                        "nombre":fileObj.name,
                        "tipo":fileObj.type,
						"img":e.target.result,
						"id_usuario":this.userdata[0].id_usuario,
					});
                    // console.log(this.FotosSub);				 
					this.fotosService.createFoto(this.FotosSub[0]).then(
                        data => {
                            // console.log(data);
                            if(typeof(data) !='undefined'){
                                this.dataimg=data;
                                this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente la foto', life: 4000});
                            }else{
                                this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No se guardado la foto', life: 3000});
                            }
                        }
                    );
                    this.FotosSub=[];
                };
              
          },
          ClickModalFoto(){
              this.modalfotosdialog=true;
              this.fotosService.getFotoIdMuestra(this.Id_muestra).then(
                        data => {
                            this.Lisimg=data;
                            this.filesLoaded = true;
                        });
          },
          closeFile(){
            if (this.fileS){
               this.fileS = null 
            }else{
                this.modalfotosdialog = false; 
                this.fileS = null
            }
          },
          EliminarFoto(event){
            //   console.log(event.id_foto);
            //   console.log(event);

              this.fotosService.deleteFoto(event.id_foto).then(
                        data => {
                            // console.log(data);
                            if(typeof(data) !='undefined'){ 
                                this.Lisimg=[];
                                 this.fotosService.getFotoIdMuestra(this.Id_muestra).then(
                                data => {
                                    this.Lisimg=data;   
                                    
                                });                               
                                this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha eliminado correctamente la foto', life: 4000});
                            }else{
                                this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No se ha podido eliminar la foto', life: 3000});
                            }
                        }
                    );
          }
           
		}
	}
</script>
 



<style scoped lang="scss">

.container {
  display: flex; /* or inline-flex */
  flex-direction: row
}

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.file {
//   opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  width: 100px;
  height: 35px;
  margin-right: 20px;

//   border-radius: 25px;
  background: #2196F3;
//   box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
//   transition: transform .2s ease-out;
}

.file-name {
  position: absolute;
  bottom: -15px;
  left: 120px;
  font-size: 0.85rem;
  color: #555;
}

input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

/* Adding an outline to the label on focus */
input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.calendar-m{
    margin-bottom: 10px;;
}

.file-list{
    list-style: none;
    padding: 0;
    
}
.file-list li{
    font-size: 1.1em;
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-link{
    padding: .25rem;
    border-radius: .25rem;
    width: 20rem;
    white-space: nowrap;
    overflow: hidden;
}
.file-link:hover{
 cursor: pointer;
 background: rgba($color: #000000, $alpha: .10);
}

.del-button{
    padding: .5rem;
    border-radius: 50%;
}
.del-button:hover{
    cursor: pointer;
    background: rgba($color: #000000, $alpha: .10);
}

.my-button{
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
}

.my-button:hover{
    cursor: pointer;
    color: #313438;
    background: rgba($color: #000000, $alpha: .10);
}

.my-header{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

</style>
